import {
  Button,
  Checkbox,
  CloseIcon,
  Dialog,
  Flex,
  Input,
  Loader,
  RadioGroup,
  Text,
  TextArea,
  Card,
  CardBody,
  Image,
  Segment,
  Tooltip,
  Dropdown,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import "./MeetingDetailsForm.scss";
import { useBooking } from "../../../../../context/BookingContext";
import { useTranslation } from "react-i18next";
import BookingDialog from "../../BookingDialog/BookingDialog";
import { toast } from "react-toastify";
import userSvg from "../../../../../Assets/images/svg/user.svg";
import AddMember from "../../AddMember";
import axios from "axios";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
const url = process.env.REACT_APP_EP_URL;
const MeetingDetailsForm = (props) => {
  const {
    isLoadingData,
    userProfileData: tutorInfo,
    setIsOpenBookSlotDialog,
  } = props;
  const {
    step,
    setStep,
    meetingData,
    setMeetingData,
    otherTopics,
    setOtherTopics,
    handleBookSlot,
    showError,
    selectedMeetType,
    setSelectedMeetType,
    user,
    openEventLoginModal,
    setOpenEventLoginModal,
    handleLogin,
    handleMSLogin,
    onSuccess,
    onFailure,
    googleLoginHandler,
    isSubmitting,
    isGroupMeeting,
    setIsGroupMeeting,
    allSelectedStudentForMeeting,
    setAllSelectedStudentForMeeting,
  } = useBooking();
  const { t } = useTranslation();

  const [studentListOfSelectedClass, setStudentListOfSelectedClass] = useState(
    []
  );

  const [
    filteredStudentListOfSelectedClass,
    setFilteredStudentListOfSelectedClass,
  ] = useState([]);

  const [selectedStudentsOfSelectedClass, setSelectedStudentsOfSelectedClass] =
    useState([]);
  const [allStudentsForGroup, setAllStudentsForGroup] = useState([]);
  const [classDropdownList, setClassDropdownList] = useState([]);
  const [selectedStudentToDelete, setSelectedStudentToDelete] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isClassDataLoading, setIsClassDataLoading] = useState(false);
  const [studentSearchText, setStudentSearchText] = useState("");
  const [selectedStudentByNameEmail, setSelectedStudentByNameEmail] = useState(
    []
  );

  const getClassroomStudents = async () => {
    try {
      setIsClassDataLoading(true);
      const { data } = await axios({
        method: "GET",
        url: `${url}/api/classroom${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (data?.result?.length) {
        let classNames = [];
        const formattedData = data.result.reduce((acc, classData) => {
          const { ClassName, StudentData } = classData;
          if (!classNames.includes(ClassName)) {
            classNames.push(ClassName);
            let formattedClass = {
              class: ClassName,
              students:
                Array.isArray(StudentData) && StudentData.length
                  ? StudentData.map(
                      ({ email, firstName, lastName, photo, ...rest }) => {
                        return {
                          ...rest,
                          studentEmailId: email,
                          name: `${firstName} ${lastName ? lastName : ""}`,
                          studentImageUrl: photo
                            ? photo + user?.SASToken
                            : userSvg,
                        };
                      }
                    )
                  : [],
            };
            return [...acc, formattedClass];
          } else {
            return acc;
          }
        }, []);
        setClassData(formattedData);
        setClassDropdownList(formattedData.map((item) => item.class));
      } else {
        setClassData([]);
        setClassDropdownList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsClassDataLoading(false);
    }
  };

  const formateStudentsSelectedForMeeting = async (
    selectedStudentByNameEmail,
    selectedStudentsOfSelectedClass
  ) => {
    let students = [];
    const formattedSelectedStudentByNameEmail = await Promise.all(
      selectedStudentByNameEmail.map((student) => ({
        ...student,
        image: student.image || userSvg,
        key: student.email,
      }))
    );
    const formattedSelectedStudentsOfSelectedClass = await Promise.all(
      selectedStudentsOfSelectedClass.map((student) => ({
        ...student,
        header: student.name,
        image: student.studentImageUrl,
        key: student.studentEmailId,
      }))
    );
    students = [
      ...formattedSelectedStudentByNameEmail,
      ...formattedSelectedStudentsOfSelectedClass,
    ];
    setAllSelectedStudentForMeeting(students);
  };

  useEffect(() => {
    getClassroomStudents();
  }, []);

  useEffect(() => {
    let filteredData = studentListOfSelectedClass;

    if (studentSearchText.length) {
      filteredData = filteredData.filter((data) =>
        data.name?.toLowerCase()?.includes(studentSearchText?.toLowerCase())
      );
    }
    setFilteredStudentListOfSelectedClass(filteredData);
  }, [studentListOfSelectedClass, studentSearchText]);

  const studentCard = ({ student }) => {
    return (
      <Card
        elevated
        className="fit-content all-feedback-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          width: "223px",
          height: "50px",
          margin: "10px",
          borderRadius: "7px",
        }}
      >
        <CardBody className="student-card-body" style={{ padding: "10px" }}>
          <Flex wrap space="between" vAlign="center">
            <Flex>
              <Flex>
                <Image
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                    borderRadius: "4px",
                  }}
                  src={student.studentImageUrl}
                />
              </Flex>
              <Flex column vAlign="start">
                <Tooltip
                  content={
                    student.name ?? student.studentEmailId?.split("@")?.[0]
                  }
                  trigger={
                    <Text className="student-name-wrapper">
                      {student.name ?? student.studentEmailId?.split("@")?.[0]}
                    </Text>
                  }
                />
              </Flex>
            </Flex>
            <Flex>
              <Checkbox
                checked={selectedStudentsOfSelectedClass.some(
                  (item) => item.studentEmailId === student.studentEmailId
                )}
                onClick={(e, { checked }) => {
                  if (checked) {
                    if (
                      allStudentsForGroup.some(
                        (item) => item.studentEmailId === student.studentEmailId
                      )
                    ) {
                      toast.warning(feedbackTranslation.studentExistValidation);
                      return;
                    }
                    setSelectedStudentsOfSelectedClass([
                      ...selectedStudentsOfSelectedClass,
                      student,
                    ]);
                  } else {
                    setSelectedStudentsOfSelectedClass((prevData) =>
                      prevData.filter(
                        (item) => item.studentEmailId !== student.studentEmailId
                      )
                    );
                  }
                }}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const feedbackTranslation = t("feedbackModule").body.group;
  const translation = t("booking").meetingDetailsForm;
  const eventTranslation = t("events").body.eventForm;
  const commonTranslation = t("booking").common;
  const signInDialogTranslation = t("booking").signInDialog;

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div className="App event-form google-login-btn">
          <GoogleLogin
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onError={onFailure}
            scope="https://www.googleapis.com/auth/calendar.events"
          />
        </div>
      </GoogleOAuthProvider>
      <Dialog
        cancelButton={commonTranslation.cancel}
        confirmButton={signInDialogTranslation.signIn}
        open={openEventLoginModal.isOpen}
        onCancel={() => {
          setOpenEventLoginModal({ isOpen: false, type: null });
          setSelectedMeetType("");
        }}
        onConfirm={() => {
          setOpenEventLoginModal({ ...openEventLoginModal, isOpen: false });
          if (openEventLoginModal.type === "isMSTeamMeeting") {
            handleMSLogin({ type: "isMSTeamMeeting", value: true });
          } else if (openEventLoginModal.type === "isGoogleMeetMeeting") {
            googleLoginHandler();
          }
        }}
        closeOnOutsideClick={false}
        content={
          <div>
            <Flex wrap>
              {openEventLoginModal.type === "isMSTeamMeeting"
                ? eventTranslation.MSTeamsMeeting.signInConfirmation
                : eventTranslation.googleMeetMeeting.signInConfirmation}
            </Flex>
          </div>
        }
        header={signInDialogTranslation.signIn}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setOpenEventLoginModal({
              isOpen: false,
              type: null,
            });
            setSelectedMeetType("");
          },
        }}
      />

      <Flex column className="meeting-details-form-wrapper" gap="gap.medium">
        {/* <p className="header">{translation.meetingDetails}</p> */}
        <div>
          <p className="sub-header">{translation.meetingName.header}</p>
          <Input
            fluid
            value={meetingData.name}
            onChange={(e) => {
              setMeetingData({ ...meetingData, name: e.target.value });
            }}
            disabled={isSubmitting}
          />
          {showError && !meetingData.name && (
            <Text error content={translation.meetingName.validation} />
          )}
        </div>

        <div>
          <p className="sub-header">{translation.addANote.header}</p>
          <TextArea
            fluid
            resize="vertical"
            value={meetingData.note}
            onChange={(e) => {
              setMeetingData({ ...meetingData, note: e.target.value });
            }}
            disabled={isSubmitting}
          />
          {showError && !meetingData.note && (
            <Text error content={translation.addANote.validation} />
          )}
        </div>
        <div>
          <p className="sub-header">{translation.topics.header}</p>
          {isLoadingData ? (
            <Flex vAlign="center" hAlign="center">
              <Loader />
            </Flex>
          ) : tutorInfo?.ProfileSpecialities?.length ? (
            <Flex gap="gap.small" wrap>
              {tutorInfo.ProfileSpecialities.map((topic) => {
                return (
                  <Checkbox
                    key={topic.id}
                    label={topic.title}
                    disabled={isSubmitting}
                    checked={meetingData.topics.includes(topic.title)}
                    onChange={(e, { checked }) => {
                      if (checked) {
                        setMeetingData({
                          ...meetingData,
                          topics: [...meetingData.topics, topic.title],
                        });
                      } else {
                        setMeetingData({
                          ...meetingData,
                          topics: meetingData.topics.filter(
                            (topic_) => topic_ !== topic.title
                          ),
                        });
                      }
                    }}
                  />
                );
              })}
              <Checkbox
                label={translation.other.header}
                disabled={isSubmitting}
                checked={meetingData.topics.includes("Other")}
                onChange={(e, { checked }) => {
                  if (checked) {
                    setMeetingData({
                      ...meetingData,
                      topics: [...meetingData.topics, "Other"],
                    });
                  } else {
                    setMeetingData({
                      ...meetingData,
                      topics: meetingData.topics.filter(
                        (topic_) => topic_ !== "Other"
                      ),
                    });
                  }
                }}
              />
            </Flex>
          ) : (
            <Checkbox
              label={translation.other.header}
              disabled={isSubmitting}
              checked={meetingData.topics.includes("Other")}
              onChange={(e, { checked }) => {
                if (checked) {
                  setMeetingData({
                    ...meetingData,
                    topics: [...meetingData.topics, "Other"],
                  });
                } else {
                  setMeetingData({
                    ...meetingData,
                    topics: meetingData.topics.filter(
                      (topic_) => topic_ !== "Other"
                    ),
                  });
                }
              }}
            />
          )}

          {showError && !meetingData.topics.length && (
            <Text error content={translation.topics.validation} />
          )}
          {meetingData?.topics?.includes("Other") ? (
            <>
              <p className="sub-header" style={{ marginTop: "15px" }}>
                {translation.otherTopics.header}
              </p>
              <Input
                fluid
                value={otherTopics}
                disabled={isSubmitting}
                onChange={(e) => {
                  setOtherTopics(e.target.value);
                }}
              />
            </>
          ) : null}
        </div>
        <div>
          <Flex column gap="gap.smaller">
            <Checkbox
              label={translation.isGroupMeeting}
              checked={isGroupMeeting}
              toggle
              disabled={isSubmitting}
              onChange={() => {
                setIsGroupMeeting(!isGroupMeeting);
              }}
            />
            {isGroupMeeting && (
              <p className="sub-header">{translation.addMembers}</p>
            )}
            {isGroupMeeting && (
              <BookingDialog
                closeOnOutsideClick={false}
                button={
                  <Dropdown
                    disabled={isSubmitting.isLoading || isClassDataLoading}
                    multiple
                    fluid
                    className="meeting-users-selector"
                    placeholder={translation.selectMembers}
                    value={allSelectedStudentForMeeting}
                  />
                }
                header={feedbackTranslation.addMembers}
                headerAction={{
                  icon: <CloseIcon />,
                  title: commonTranslation.close,
                  onClick: () => {
                    setStudentListOfSelectedClass([]);
                    setSelectedStudentsOfSelectedClass([]);
                    setSelectedClass(null);
                  },
                }}
                confirmButton={commonTranslation.done}
                onConfirm={() => {
                  formateStudentsSelectedForMeeting(
                    selectedStudentByNameEmail,
                    selectedStudentsOfSelectedClass
                  );
                  setSelectedClass(null);
                  setStudentListOfSelectedClass([]);
                  setSelectedStudentsOfSelectedClass([]);
                  setSelectedStudentByNameEmail([]);

                  setAllStudentsForGroup((prevData) => {
                    let students = prevData.map(
                      (student) => student.studentEmailId
                    );

                    const formattedMembers = selectedStudentByNameEmail.map(
                      ({ header, role, email, image, userID }) => ({
                        name: header,
                        role,
                        studentEmailId: email,
                        studentImageUrl: image,
                        userID,
                      })
                    );

                    let formattedData = [
                      ...selectedStudentsOfSelectedClass,
                      ...formattedMembers,
                    ].filter((data) => !students.includes(data.studentEmailId));
                    return [...prevData, ...formattedData];
                  });
                }}
                content={
                  <AddMember
                    {...props}
                    classDropdownList={classDropdownList}
                    setSelectedClass={setSelectedClass}
                    setSelectedStudentsOfSelectedClass={
                      setSelectedStudentsOfSelectedClass
                    }
                    setStudentListOfSelectedClass={
                      setStudentListOfSelectedClass
                    }
                    classData={classData}
                    selectedStudentsOfSelectedClass={
                      selectedStudentsOfSelectedClass
                    }
                    studentListOfSelectedClass={studentListOfSelectedClass}
                    allStudentsForGroup={allStudentsForGroup}
                    setStudentSearchText={setStudentSearchText}
                    studentSearchText={studentSearchText}
                    filteredStudentListOfSelectedClass={
                      filteredStudentListOfSelectedClass
                    }
                    studentCard={studentCard}
                    selectedClass={selectedClass}
                    selectedStudentByNameEmail={selectedStudentByNameEmail}
                    setSelectedStudentByNameEmail={
                      setSelectedStudentByNameEmail
                    }
                  />
                }
              />
            )}
          </Flex>
        </div>
        <div>
          <div>
            <Checkbox
              label={translation.microsoftTeamsMeeting}
              checked={selectedMeetType === "isMSTeamMeeting"}
              toggle
              disabled={isSubmitting}
              onChange={() => {
                setSelectedMeetType("isMSTeamMeeting");
                handleLogin("isMSTeamMeeting");
              }}
            />
            <Checkbox
              disabled={isSubmitting}
              label={translation.googleMeetMeeting}
              checked={selectedMeetType === "isGoogleMeetMeeting"}
              toggle
              onChange={() => {
                setSelectedMeetType("isGoogleMeetMeeting");
                handleLogin("isGoogleMeetMeeting");
              }}
            />
          </div>
          {showError && !selectedMeetType && (
            <Text error content={translation.meetingTypeValidation} />
          )}
        </div>
        <Flex hAlign="end" gap="gap.medium">
          <Button
            onClick={() => {
              setIsOpenBookSlotDialog(false);
            }}
            disabled={isSubmitting}
          >
            {commonTranslation.cancel}
          </Button>
          <Button
            disabled={isSubmitting}
            primary
            onClick={() => {
              handleBookSlot();
            }}
          >
            {isSubmitting ? (
              <Flex gap="gap.small" vAlign="center">
                <Loader size="small" />{" "}
                <span>{`${translation.scheduling}...`}</span>
              </Flex>
            ) : (
              "Schedule meeting"
            )}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default MeetingDetailsForm;
